import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/api/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    form: null,
    response: null,
  },
  actions: {
    async sendMail({ commit } , info){
      try {
        const form = {
          _subject: 'UpCities',
          _template: 'table',
          _captcha: 'false',
          'Nome': info.name,
          email: info.email,
          'Telefone': info.telephone,
          'UF': info.state,
          'Cidade': info.city,
          'Mensagem': info.message,
        };
        const response = await api.sendEmail(form);

        commit('SET_RESPONSE', response.data);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error.response);
      }
    }
  },
  modules: {
  },
  mutations: {
    SET_RESPONSE(state, payload){
      state.response = payload;
    }
  },
});
