<template>
  <div class="home">
    <FloatingMenu v-if="false" />
    <Header />
    <MainBanner />
    <VideoBanner />
    <BenefitsBanner />
    <Slider />
    <ContaUnicaBanner />
    <PartnersBanner />
    <!-- <RatingsBanner /> -->
    <NumbersBanner />
    <SmartCitiesBanner />
    <FormBanner />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/common/Header.vue';
import MainBanner from '@/components/banners/MainBanner.vue';
import VideoBanner from '@/components/banners/VideoBanner.vue';
import BenefitsBanner from '@/components/banners/BenefitsBanner.vue';
import PartnersBanner from '@/components/banners/PartnersBanner.vue';
import NumbersBanner from '@/components/banners/NumbersBanner.vue';
import SmartCitiesBanner from '@/components/banners/SmartCitiesBanner.vue';
import FormBanner from '@/components/banners/FormBanner.vue';
/* import RatingsBanner from '@/components/banners/RatingsBanner.vue'; */
import ContaUnicaBanner from '@/components/banners/ContaUnicaBanner.vue';
import Slider from '@/components/slider/Slider.vue';
import Footer from '@/components/common/Footer.vue';
import FloatingMenu from '@/components/common/FloatingMenu.vue';

export default {
  name: 'Home',
  title: 'UpCities - Inovação e praticidade para sua cidade',

  components: {
    Header,
    MainBanner,
    VideoBanner,
    BenefitsBanner,
    PartnersBanner,
    NumbersBanner,
    SmartCitiesBanner,
    FormBanner,
/*     RatingsBanner, */
    ContaUnicaBanner,
    Slider,
    Footer,
    FloatingMenu,
  }
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
}
</style>
